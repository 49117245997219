import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Demo from '../../components/Demo';
// import Data from '../../utils/data';
import './Learn.scss';
// import {Button} from 'mw-style-react';
import LearnItem from './components/LearnItem';
import VideoCarousel from './components/VideoCarousel';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Image from '../../components/Image/Image';

class Learn extends PureComponent {
  render() {
    const { intl, data } = this.props;

    return (
      <section className="learn">
        <HeaderPage data={data.header}/>
        <div className="learn__content__wrapper">
          <div className="learn__video">
            <VideoCarousel data={data.video}/>
          </div>
          <div className="learn__description">
            {intl.formatMessage({id: data.description})}
          </div>
          <div className="learn__content">
            {
              data.content.map((item, index) => (
                <LearnItem
                  key={index}
                  item={item}
                  intl={intl}
                />
              ))
            }
          </div>
          <div className="learn__information">
            <Image file={'excMark.png'}/>
            <div>
              {intl.formatMessage({id: data.information})}
            </div>
          </div>
        </div>
        <Demo event={data.demo.event}/>
      </section>
    );
  }
}


Learn.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object
};

export default injectIntl(Learn);

