import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import LearnPage from '../templates/Learn';

// let lang = 'ru';
// if (typeof window !== 'undefined') {
//   lang = window.localStorage.getItem('language');
// }

const learn = {
  header: {
    title: 'learnTitle',
    subTitle: 'learnSubTitle',
    image: 'brain.png',
    // button1: {
    //   text: 'Зарегистрироваться',
    //   link: host`,
    // },
    // buttonPay: {
    //   text: 'learnOrderWorkshopButton',
    //   link: '/workshops',
    // }
  },
  video: {
    title: 'learnVideoTitle',
    list: [
      {
        title: 'learnVideoPart1Title',
        list: [
          'learnVideoPart1List1',
          'learnVideoPart1List2',
          'learnVideoPart1List3',
          {
            title: 'learnVideoPart1List4',
            list: [
              'learnVideoPart1List4Item1'
            ]
          },
        ],
        src: 'https://www.youtube.com/embed/998OEQnoyWc?color=white&rel=0&enablejsapi=1',
      },
      {
        title: 'learnVideoPart2Title',
        list: [
          {
            title: 'learnVideoPart2List1',
            list: [
              'learnVideoPart2List1Item1',
              'learnVideoPart2List1Item2'
            ]
          },
          {
            title: 'learnVideoPart2List2',
            list: [
              'learnVideoPart2List2Item1'
            ]
          }
        ],
        src: 'https://www.youtube.com/embed/DdOmS7Y3rmA?color=white&rel=0&enablejsapi=1',
      },
      {
        title: 'learnVideoPart3Title',
        list: [
          {
            title: 'learnVideoPart3List1',
            list: [
              'learnVideoPart3List1Item1'
            ]
          },
          {
            title: 'learnVideoPart3List2',
            list: [
              'learnVideoPart3List2Item1'
            ]
          }
        ],
        src: 'https://www.youtube.com/embed/0yZ34s1WPIs?color=white&rel=0&enablejsapi=1',
      },
      {
        title: 'learnVideoPart4Title',
        list: [
          {
            title: 'learnVideoPart4List1',
            list: [
              'learnVideoPart4List1Item1'
            ]
          },
          {
            title: 'learnVideoPart4List2',
            list: [
              'learnVideoPart4List2Item1'
            ]
          },
          {
            title: 'learnVideoPart4List3',
            list: [
              'learnVideoPart4List3Item1'
            ]
          }
        ],
        src: 'https://www.youtube.com/embed/2HzuPHL3FZ0?color=white&rel=0&enablejsapi=1',
      },
      {
        title: 'learnVideoPart5Title',
        list: [
          {
            title: 'learnVideoPart5List1',
            list: [
              'learnVideoPart5List1Item1'
            ]
          },
          'learnVideoPart5List2'
        ],
        src: 'https://www.youtube.com/embed/_0dSZj87nDg?color=white&rel=0&enablejsapi=1',
      }
    ],
  },
  content: [
    {
      title: 'learnContentStartTitle',
      subTitle: 'learnContentStartSubTitle',
      text: 'learnContentStartText',
      image: 'rocket.png',
      button: {
        text: 'learnContentStartButton',
        link: 'learnContentStartButtonLink',
        event: 'Learn_get_started'
      }
    },
    {
      title: 'learnContentTutorialTitle',
      subTitle: 'learnContentTutorialSubTitle',
      text: 'learnContentTutorialText',
      image: 'casual-life.png',
      button: {
        text: 'learnContentTutorialButton',
        // link: 'learnContentTutorialButtonLink',
        link: '/tutorials',
        event: 'Learn_views_tutorials'
      }
    },
    {
      title: 'learnContentDocTitle',
      subTitle: 'learnContentDocSubTitle',
      text: 'learnContentDocText',
      image: 'book.png',
      button: {
        text: 'learnContentDocButton',
        link: 'learnContentDocButtonLink',
        event: 'Learn_read_documentation'
      }
    },
    // {
    //   title: 'Вебинары',
    //   subTitle: 'Уникальное групповое обучение для повышения навыков работы в Corezoid',
    //   text: 'Подключайтесь к групповому изучению Corezoid на практических примерах в
    //     режиме онлайн. Наши тренера покажут кейсы применения Corezoid и расскажут о
    //     лучших практиках.',
    //   image: web,
    //   button: {
    //     text: 'Начать изучение',
    //     link: '/webinars'
    //   }
    // },
    {
      title: 'learnContentWorkShopTitle',
      subTitle: 'learnContentWorkShopSubTitle',
      text: 'learnContentWorkShopText',
      image: 'cap.png',
      button: {
        text: 'learnContentWorkShopButton',
        link: '/workshops',
        event: 'Learn_order_a_workshop'
      }
    },
    {
      title: 'learnContentCertificationTitle',
      subTitle: 'learnContentCertificationSubTitle',
      text: 'learnContentCertificationText',
      image: 'sports_medal.png',
      button: {
        text: 'learnContentCertificationButton',
        link: '/certification',
        event: 'Learn_pass_certification'
      }
    },
    // {
    //   title: 'learnContentCommunityTitle',
    //   subTitle: 'learnContentCommunitySubTitle',
    //   text: 'learnContentCommunityText',
    //   image: 'busts-in-silhouette_community.png',
    //   button: {
    //     text: 'learnContentCCommunityButton',
    //     link: 'https://community.corezoid.com/categories',
    //     event: 'Learn_join_community'
    //   }
    // },
  ],
  description: 'learnContentDescription',
  information: 'learnInformation',
  demo: {
    event: 'Learn_digital_core_start'
  }
};

const Learn = (props) => (
  <Layout {...props}>
    <LearnPage
      data={learn}
    />
  </Layout>
);


export default withIntl(Learn);

