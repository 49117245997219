import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'mw-style-react';
import './LearnItem.scss';
import { Link } from '../../../../i18n';
import Image from '../../../../components/Image/Image';

class LearnItem extends PureComponent {
  render() {
    const { item, intl } = this.props;

    return (
      <div className="col-4 learn__item">
        <div>
          <Image file={item.image}/>
          <div className="learn__item__title">
            {intl.formatMessage({id: item.title})}
          </div>
          <div className="learn__item__subTitle">
            {intl.formatMessage({id: item.subTitle})}
          </div>
          <div className="learn__item__text">
            {intl.formatMessage({id: item.text})}
          </div>
        </div>
        <Link
          to={`${intl.formatMessage({id: item.button.link})}`}
          event={item.button.event}
        >
          <Button
            className={'button__default learn__item__btn'}
            label={intl.formatMessage({id: item.button.text})}
          />
        </Link>
      </div>
    );
  }
}


LearnItem.propTypes = {
  item: PropTypes.object,
  intl: PropTypes.object,
};

export default LearnItem;

